import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import React from 'react';
import { theme, Theme } from '../store/theme';
import Img from './Img';

import styles from './SocialLink.module.scss';

interface FooterSocialLinkProps {
  icon: string,
  link: string,
  id: string,
  className?: string,
  onClick?: () => void,
}

const SocialLink = ({
  icon, id, link, className, onClick,
}: FooterSocialLinkProps) => {
  const appTheme = useAtomValue<Theme>(theme);

  return (
    <div className={classnames(styles.social, className)}>
      <a
        href={link}
        target='_blank'
        className={styles.social__link}
        rel='noreferrer'
        onClick={() => onClick?.()}
      >
        <Img
          src={`/media/socials/social-particles-${appTheme}.webp`}
          alt='particles'
          className={styles.social__link__particles}
        />
        <svg className={styles.icon}>
          <use
            xlinkHref={`${icon}#${id}`}
            href={`${icon}#${id}`}
          />
        </svg>
      </a>
    </div>
  );
};

export default SocialLink;
