import { RefObject, useEffect } from 'react';

type CallbackFunction = () => void;

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: CallbackFunction,
): void => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
